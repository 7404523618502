/* Forms */

.form-group {
  margin-bottom: 1.5rem;
}

.bp3-toast-container-top{
    z-index: 9999 !important;
}

.bp3-popover-target {
    width: 100% !important;

    input {
        border: 1px solid #dee2e6;
        font-family: "Roboto", sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
        padding: 8px 12px;
        box-shadow: none;
        height: 3.175rem;
        line-height: 1.5;
        border-radius: 0.3rem;
    }
}

.input-group-append,
.input-group-prepend {
    color: $input-placeholder-color;
    width: auto;
    border: none;

    .input-group-text {
        border-color: $border-color;
        padding: 0.94rem 0.5rem 0.94rem 1rem;
        color: $input-placeholder-color;
    }

    button {
        padding-top: .3rem;
        padding-bottom: .3rem;
    }
}

.custom-control {
  .custom-control-label {
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.form-control {
  border: 1px solid $border-color;
  font-family: $type1;
  font-size: $input-font-size;
  font-weight: 400;
  padding: 8px 12px;
}

select {
  &.form-control {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid $border-color;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color,
    $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
.form-check-input {
  margin-left: 0;
}