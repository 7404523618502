/* Layouts */


.bp3-overlay-content{
    z-index: 99999999999999;
}

.bp3-dialog {
    .bp3-popover-target {
        width: auto !important;
    }
}

.table-hover {
    .bp3-popover-target {
        width: auto !important;
    }
}

.card-header.open {
    background-color: #0c7cd5;
    color: white;
}
.card-header.open:hover {
    background-color: #0c7cd5;
    color: white;
}

.card-header.closed {
    background-color: #2A5073;
    color: white;
}

.card-header.closed:hover {
    background-color: #336191;
    color: white;
}

.card-header.opendeleted {
    background-color: #CC0000;
    color: white;
}

.card-header.opendeleted:hover {
    background-color: #CC0000;
    color: white;
}

.outgoing_msg {
    overflow: hidden;
    margin: 26px 0 26px;
}

.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}

.messageContainer {
    max-height: 250px;
    overflow: overlay;
    padding-right: 40px;
}

.color-red {
    color: red !important;
}

.received_withd_msg {
    width: 57%;

    p {
        background: #ebebeb none repeat scroll 0 0;
        border-radius: 3px;
        color: #646464;
        font-size: 14px;
        margin: 0;
        padding: 5px 10px 5px 12px;
        width: 100%;
    }
}

.sent_msg {
    float: right;
    width: 46%;

    p {
        background: #05728f none repeat scroll 0 0;
        border-radius: 3px;
        font-size: 14px;
        margin: 0;
        color: #fff;
        padding: 5px 10px 5px 12px;
        width: 100%;
    }

    a {
        color: #fff;
    }
}

// Sidebar Mini
.sidebar-mini {
    @media (min-width: 992px) {
        .page-body-wrapper {
            width: calc(100% - #{$sidebar-width-mini});

            .navbar {
                &.navbar-mini {
                    &.fixed-top {
                        left: $sidebar-width-mini;
                    }
                }
            }
        }

        .sidebar {
            width: $sidebar-width-mini;

            .nav {
                .sidebar-category {
                    text-align: center;
                }

                .nav-item {
                    padding: 0;

                    .nav-link {
                        @include display-flex;
                        @include align-items(center);
                        @include justify-content(center);
                        @include flex-direction(column);
                        text-align: center;
                        position: relative;
                        border-bottom: none;

                        .menu-title {
                            display: block;
                            margin: auto;
                        }

                        .badge {
                            margin-left: 5px;
                            display: none;
                        }

                        i {
                            &.menu-icon {
                                margin-right: auto;
                                margin-left: auto;
                                margin-bottom: 0.625rem;
                            }

                            &.menu-arrow {
                                display: inline-block;
                                margin-left: 5px;
                                position: absolute;
                                top: 50%;
                                right: 20px;
                                @include transform(translateY(-50%));
                            }
                        }
                    }
                }
            }
        }

        &:not(.sidebar-icon-only) {
            //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
            .navbar {
                left: $sidebar-width-mini;

                .navbar-menu-wrapper {
                    width: calc(100% - #{$sidebar-width-mini});
                }
            }

            .sidebar {
                .nav {
                    &.sub-menu {
                        padding: 0;
                        border-top: none;

                        .nav-item {
                            &::before {
                                left: 1.562rem;
                            }

                            .nav-link {
                                padding: 0.75rem 0 0.75rem 0.5rem;
                                margin-left: auto;
                                margin-right: auto;
                                display: block;
                                text-align: left;
                                width: 66%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      left: $sidebar-width-icon;
      .rtl & {
        left: 0;
        right: 150px;
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          padding: 0;
          .nav-link {
            display: block;
            text-align: center;
            padding: 16px 16px;
            .menu-title,
            .badge,
            .menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title {
                @include border-radius(0 5px 0 0px);
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }
          &.nav-profile {
            display: none;
          }
          &.nav-category {
            display: none;
          }
          &.nav-doc {
            margin: 0;
            i {
              display: block;
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $sidebar-light-menu-hover-bg;
                @at-root #{selector-append(".sidebar-dark", &)} {
                  background: $sidebar-dark-menu-active-bg;
                }
                padding: 0.5rem 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                }
                @at-root #{selector-append(".sidebar-dark", &)} {
                  color: $white;
                }
                &:after {
                  display: none;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: 0.5rem 0;
              background: $sidebar-light-menu-hover-bg;
              @include border-radius(0 0 5px 0);
              @at-root #{selector-append(".sidebar-dark", &)} {
                background: $sidebar-dark-bg;
              }
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
                @include border-radius(0 0 0 5px);
              }
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            padding: 0 1.25rem 0 1.25rem;
            .nav-link {
              text-align: left;
              padding-left: 20px;
            }
          }
          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-right: auto;
              margin-left: 0;
              .nav-link {
                text-align: right;
                &:before {
                  left: 0;
                  right: unset;
                }
              }
            }
          }
        }
      }
      .sidebar-actions {
        display: none;
      }
    }
    .page-body-wrapper {
      transition: all $action-transition-duration $action-transition-timing-function;
      -webkit-transition: all $action-transition-duration $action-transition-timing-function;
      -moz-transition: all $action-transition-duration $action-transition-timing-function;
      -ms-transition: all $action-transition-duration $action-transition-timing-function;
    }
    .main-panel {
      width: 100%;
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .navbar {
      left: 0;
      .navbar-menu-wrapper {
        width: 100%;
      }
    }
    .sidebar {
      width: 0;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
      }
    }
    .page-body-wrapper {
      width: 100%;
    }
    .main-panel {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;
      width: 100%;
      .sidebar {
        transition: none;
        height: 100%;
      }
    }

    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
      }
      .page-body-wrapper {
        width: 100%;
      }
    }

    .main-panel {
      width: 100%;
      transition: none;
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  @media (min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;

      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;

        &.sub-menu {
          max-height: none;
        }
      }
    }

    .page-body-wrapper {
      width: 100%;
      margin-left: $sidebar-width-lg;
    }

    &.sidebar-icon-only {
      .page-body-wrapper {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: $boxed-layout-bg;
      padding: 0 calc((100% - #{$boxed-container-width}) / 2);
    }
    .navbar {
      &.fixed-top {
        @media (min-width: 1200px) {
          margin: 0 calc((100% - #{$boxed-container-width}) / 2);
          max-width: $boxed-container-width;
        }
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;

  .sidebar {
    .nav {
      padding-right: 0;
      .nav-item {
        .nav-link {
          text-align: middle;
        }
      }
    }
  }
  .page-body-wrapper {
    transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
  }
  .navbar {
    transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
    right: $sidebar-width-lg;
    @media (max-width:900px) {
      right: 0;
    }
  }
  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  &.sidebar-icon-only {
    .navbar {
    right: $sidebar-width-icon;
    left: 0;
    }
  }
}

.order-cancelled {
    background-color: #A80600 !important;
    color: white !important;
}

.order-cancelled-soft {
    background-color: #AB3632 !important;
    color: white !important;
}


.order-with-claim {
    background-color: #FFC300 !important;
    color: white !important;
}

.order-with-claim-soft {
    background-color: #FFDE74 !important;
    color: white !important;
}

.select-focus {
    outline: 1px solid #dee2e6 !important;
    color: #495057 !important;
}

.bp3-spinner-animation {
    svg {
        width: 200px;
        height: 200px;
    }
}

.sidebar .nav .nav-item .nav-link {
    height: 20px;
}

.sidebar .nav .nav-item .nav-link .menu-title {
    font-size: 14px !important;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
    font-size: 14px !important;
    height: 30px;
}


.sidebar .nav .nav-item.active > .nav-link .menu-title, .sidebar .nav .nav-item.active > .nav-link i {
    font-weight: 800;
}

.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-profile) > .nav-link {
    color: #2196f3;
}

.sidebar .nav.sub-menu .nav-item:hover > .nav-link {
    color: #2196f3;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
    font-weight: 800;
}

.menu-header {
    color: #323232;
}

.borderNone{
    border-top: none !important;
}

.table-sku-container .bp3-input-group {
    width: 400px;
}

.table-sku-container-desglose .bp3-input-group {
    width: 350px;
}

.icon-title{
    font-size: 30px;
}

.card-header{
    cursor: pointer;
}

.card-header:hover {
    background-color: rgb(237, 237, 237);
}



.card {
    /* border: none; */
    /*box-shadow: 0 0 8px 6px rgb(0 0 0 / 2%);*/
}

.form-check .form-check-label input[type="checkbox"].highlightSelected:focus + .input-helper:before {
    border-color: rgba(33, 150, 243, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px rgba(33, 150, 243, 0.8);
    outline: 0 none;
}

.popoverSuggestMS .bp3-menu {
    max-height: 160px !important;
}

span.bp3-menu-item-label.labelSuggestMS {
    width: 200px;
    margin-left: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: italic;
    color: #b3b3b3;
}
span.bp3-menu-item-label.labelSuggestMS.driver {
    width: 120px;
}

.naranja {
    background-color: #da9033;
    color: #ffffff;
}

.naranja p {
    color: #ffffff;
}

:checked ~ span {
    font-weight: bold !important;
}

.MSSuggest .bp3-popover-target {
    width: 80% !important;
}

.tachado-rojo {
    color: red !important;
    text-decoration:line-through; 
}

.nowrap {
    white-space: nowrap !important;
}

.link-texto {
    text-decoration: underline;
    color: #19d895 !important;
}